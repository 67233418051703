@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
      margin-left: auto;
      margin-right: auto;
      width: 300px
    }
  
    .Login form {
      max-width: 320px;
    }

    .Register {
      padding: 60px 0;
      margin-left: auto;
      margin-right: auto;
      width: 500px
    }
  }
  